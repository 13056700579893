export default {
  props: {
    /**
     * Button primary type. Primary style of button.
     * @see https://getuikit.com/docs/button
     * @values default, primary, secondary, danger, text, link
     */
    type: {
      type: String,
      default: "primary",
    },
    /**
     * Size modifier for button
     * Values other than specified are ignored
     * @values small, large
     */
    size: {
      type: String,
      default: "medium",
    },
    /**
     * Is this loading right now?
     * If so, a spinner is displayed instead of the button content
     */
    loading: {
      type: [Boolean],
      default: false,
    },
    /**
     * Force icon ratio.
     * Number or number like string for setting the ratio
     * false for allowing component to set the ratio
     **/
    iconRatio: {
      type: [String, Number, Boolean],
      default: false,
    },
    /**
     * takes priority over iconRatio
     */
    iconSize: {
      type: [String, Number, Boolean],
      default: false,
    },
    /**
     * Icon for the button
     * Used as class name, works with any icon in the framework
     */
    icon: {
      type: [Boolean, String],
      default: false,
    },
    /**
     * Icon to be position after the slot
     **/
    iconEnd: {
      type: [Boolean, String],
      default: false,
    },
    /**
     * Is the button disabled
     **/
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Should the button be full width
     */
    fullWidth: {
      type: [Boolean, String],
      default: false,
    },
    /**
     *  Should the slot be rendered (use false if you dont want content in the button)
     */
    showSlot: {
      type: Boolean,
      default: true,
    },
    /**
     * Prop to catch class assignment, we will manage it ourselves with a Computed
     */
    class: {
      type: [String],
      default: "",
    },
    /**
     * Class of the inner button element
     */
    innerClass: {
      type: [String],
      default: "",
    },
    /**
     * When true, will limit side padding
     */
    slim: {
      type: [Boolean, String],
      default: false,
    },
    /**
     * Button behaviour, calculated into the "type" attribute or other things
     * Special values: "none" for no actions and "custom" for custom events - todo
     */
    behaviour: {
      type: String,
      default: "submit",
    },
    text: {
      type: [String, Boolean],
      default: "core.buttonTextDefault",
    },
  },
  data: function () {
    return {};
  },
  methods: {},
  mounted: function () {},
  computed: {
    isFullWidthComputed() {
      return this.fullWidth === true || this.fullWidth === "true";
    },
    buttonAttributes() {
      let buttonAttributes = { ...this.$.attrs };
      delete buttonAttributes.class;
      return buttonAttributes;
    },
    buttonClass() {
      let className = (this.$.attrs.class || "").split(" ");

      className.push("uk-button");

      // implement button type that modifies appearance
      className.push("uk-button-" + this.type);

      // implement other button types
      if (["reset", "default"].includes(this.type)) {
        className.push("uk-button-default");
      }

      // implement button size
      if (["small", "large", "medium"].includes(this.size)) {
        className.push("uk-button-" + this.size);
      }

      // implement button loading class
      if (this.loading) {
        className.push("uk-button-" + "loading");
      }

      if (this.isFullWidthComputed) {
        className.push("width-expand");
      }

      className.push(this.class);

      return className;
    },
    buttonStyles() {
      let styles = {};

      if (this.slim) {
        styles["padding-left"] = "10px";
        styles["padding-right"] = "10px";
      }

      return styles;
    },
    buttonType() {
      // todo: look at this, probably should be removed
      if (["submit", "reset"].includes(this.type)) {
        return this.type;
      }

      if (["submit", "reset"].includes(this.behaviour)) {
        return this.behaviour;
      }

      return "";
    },
    hasIcon() {
      return this.icon != "";
    },
    hasIconStart() {
      return this.hasIcon && this.iconPosition !== "end";
    },
    spinnerSize() {
      if (this.size === "large") {
        return 1.3;
      }

      if (this.size === "small") {
        return 0.45;
      }

      return 0.7;
    },
    iconProps() {
      let result = `icon: ${this.icon}; `;

      if (this.iconRatio) {
        result = result + "ratio: " + this.iconRatio + ";";
      }

      if (!this.iconRatio && this.size == "small") {
        result = result + "ratio: 0.8;";
      }

      return result;
    },
    iconEndProps() {
      let result = `icon: ${this.iconEnd}; `;
      if (this.iconRatio) {
        result = result + "ratio: " + this.iconRatio + ";";
      }

      if (!this.iconRatio && this.size == "small") {
        result = result + "ratio: 0.8;";
      }

      return result;
    },
    buttonOnClick() {
      if (this.behaviour === "none") {
        return function (e) {
          e.preventDefault();
        };
      } else {
        return function (e) {};
      }
    },
    finalText() {
      return this.safeTranslate(this.text);
    },
  },
  watch: {},
};
